import React from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  Drawer,
  AppBar,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  ListItem,
  Button,
  Grid,
  Icon,
  Paper,
  CircularProgress,
} from '@material-ui/core'

type ServerPageProps = {
  data: any
  setServerExpand: (value: boolean) => void
  setResetExpand: (value: boolean) => void
  setMessage: (value: string) => void
}

const ServerPage: React.FC<ServerPageProps> = ({
  data,
  setResetExpand,
  setServerExpand,
  setMessage,
}) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [selectedServer, setSelectedServer] = React.useState<string>('')
  const [expand, setExpand] = React.useState(true)
  const [externalUrl, setExternalUrl] = React.useState('')
  const [token, setToken] = React.useState('')
  const [refreshToken, setRefreshToken] = React.useState('')

  const dataString = JSON.stringify(data)
  //list for supporting iframe login
  const newLoginSupportedList = ['SIT', 'UAT']
  const application = React.useMemo(() => {
    if (data) {
      return data.filter((e: any) => e?.server === selectedServer)?.[0]
        ?.applications
    }
  }, [dataString, selectedServer])

  React.useEffect(() => {
    //jump to new site
    if (externalUrl) {
      if (newLoginSupportedList.includes(selectedServer)) {
        const portal = document.getElementById('portal')
        const data = JSON.stringify({
          token,
          refreshToken,
          firstRender: true,
        })
        window.onmessage = function (e: any) {
          const result = JSON.parse(e.data)
          if (result?.status === 'render') {
            //@ts-ignore
            portal.contentWindow.postMessage(data, '*')
          }
          if (result?.status === 'injected') {
            const initialRoute = `${externalUrl.split('/externalLogin')[0]}/${
              result?.initialRoute
            }`
            window.parent.location.href = initialRoute
          }
        }
      } else {
        window.parent.location.href = externalUrl
      }
    }
  }, [externalUrl])
  if (!data)
    return (
      <div className={classes.root}>
        <CssBaseline />
      </div>
    )
  return (
    <Paper elevation={2} className={classes.root}>
      {isSubmitting && (
        <React.Fragment>
          <div className={classes.loadingLayer} />
          <CircularProgress className={classes.loadingIcon} size={50} />
        </React.Fragment>
      )}
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.serverBar}>
          <Typography variant="h6" noWrap>
            {expand ? 'Server List' : 'Application List'}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.wrapper}>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: expand,
            [classes.drawerClose]: !expand,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: expand,
              [classes.drawerClose]: !expand,
            }),
          }}
        >
          <div className={classes.drawerContainer}>
            {!expand ? (
              <List>
                {data.map((e: any, index: number) => {
                  return (
                    <ListItem
                      button
                      key={index}
                      selected={selectedServer === e?.server}
                      onClick={() => {
                        if (e.isValid) {
                          setSelectedServer(e?.server)
                          setExpand(false)
                        } else {
                          setServerExpand(false)
                          setResetExpand(true)
                          setTimeout(() => {
                            setMessage(e.message)
                          }, 100)
                        }
                      }}
                      classes={{ selected: classes.selectedItem }}
                    >
                      <div className={classes.serverWrapper}>
                        <img
                          src={expand ? e.logo : e.icon}
                          className={clsx(classes.icon, {
                            [classes.iconOpen]: expand,
                            [classes.iconClose]: !expand,
                          })}
                        ></img>
                        <p className={classes.serverTitle}>
                          {e.serverDisplayName}
                        </p>
                      </div>
                    </ListItem>
                  )
                })}
              </List>
            ) : (
              <Grid container>
                {data.map((e: any, index: number) => {
                  return (
                    <Grid key={index} xs={6}>
                      <Button
                        style={{ width: '100%', textTransform: 'none' }}
                        onClick={() => {
                          if (e.isValid) {
                            setSelectedServer(e?.server)
                            setExpand(false)
                          } else {
                            setServerExpand(false)
                            setResetExpand(true)
                            setTimeout(() => {
                              setMessage(e.message)
                            }, 100)
                          }
                        }}
                      >
                        <div className={classes?.buttonContentWrapperForServer}>
                          <img
                            className={classes?.iconForService}
                            src={e.logo}
                          ></img>
                          <p className={classes.serverTitle}>
                            {e.serverDisplayName}
                          </p>
                        </div>
                      </Button>
                    </Grid>
                  )
                })}
              </Grid>
            )}
          </div>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentOpen]: expand,
            [classes.contentClose]: !expand,
          })}
        >
          <Grid container spacing={0}>
            {application?.map((e: any, index: number) => {
              return (
                <Grid item xs={6} key={index} style={{ textAlign: 'center' }}>
                  {e.useLocalStorage ? (
                    //different way to login
                    <Button
                      className={classes?.buttonRoot}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: e?.colour,
                        width: 155,
                        textTransform: 'none',
                      }}
                      onClick={() => {
                        setIsSubmitting(true)
                        setRefreshToken(e.refreshToken)
                        setToken(e.token)
                        if (newLoginSupportedList.includes(selectedServer)) {
                          setExternalUrl(`${e.url}/externalLogin`)
                        } else {
                          setExternalUrl(
                            `${e?.url}/user/login?token=${e?.refreshToken}`
                            //`http://localhost:3001/user/login?token=${e?.refreshToken}`
                          )
                        }
                      }}
                    >
                      <div className={classes?.buttonContentWrapper}>
                        <Icon className={classes?.buttonIcon}>
                          {e?.icon ? e?.icon : 'explicit_outlined'}
                        </Icon>
                        <p className={classes?.buttonText}>{e.name}</p>
                      </div>
                    </Button>
                  ) : (
                    //http form login
                    <form
                      action={e?.url}
                      method="POST"
                      onSubmit={() => {
                        setTimeout(() => {
                          window.parent.location.href = e?.url
                        }, 1000)
                      }}
                    >
                      <input type="hidden" name="token" value={e?.token} />
                      <Button
                        className={classes?.buttonRoot}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: e?.colour,
                          width: 155,
                          textTransform: 'none',
                        }}
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        <div className={classes?.buttonContentWrapper}>
                          <Icon className={classes?.buttonIcon}>
                            {e?.icon ? e?.icon : 'explicit_outlined'}
                          </Icon>
                          <p className={classes?.buttonText}>{e.name}</p>
                        </div>
                      </Button>
                    </form>
                  )}
                </Grid>
              )
            })}
            <iframe
              src={externalUrl}
              //src="https://localhost:3002/externalLogin"
              id="portal"
              width={0}
              height={0}
              style={{ visibility: 'hidden' }}
            />
          </Grid>
        </main>
      </div>
    </Paper>
  )
}

export default ServerPage

// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'inherit',
  },
  wrapper: {
    display: 'flex',
    height: 300,
  },
  drawer: {
    width: 160,
    flexShrink: 0,
  },
  drawerOpen: {
    position: 'inherit',
    width: 500,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    position: 'inherit',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 160,
  },
  contentOpen: {
    width: 0,
    overflowX: 'hidden',
    padding: 0,
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentClose: {
    padding: '20px 10px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 320,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
  },
  serverBar: {
    minHeight: 50,
  },
  serverWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  serverTitle: {
    textAlign: 'center',
    width: 'inherit',
  },
  logo: {
    maxWidth: 'inHerit',
  },
  icon: {
    width: 18,
  },
  iconForService: {
    width: '100%',
    height: '100%',
    maxWidth: '60px',
    marginRight: 10,
  },
  iconOpen: {
    width: 100,
    marginLeft: '4rem',
    marginRight: '4rem',
  },
  iconClose: {
    width: 18,
    marginRight: 10,
  },
  buttonRoot: {
    width: 155,
    marginBottom: 10,
    padding: 0,
  },
  buttonContentWrapperForServer: {
    width: '100%',
    padding: 5,
    fontFamily: 'system-ui',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContentWrapper: {
    padding: 5,
    fontFamily: 'system-ui',
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 12,
    fontFamily: 'system-ui',
  },
  buttonIcon: {
    fontSize: 18,
    marginRight: 3,
  },
  loadingLayer: {
    backgroundColor: 'lightgrey',
    width: '100%',
    display: 'flex',
    height: '100%',
    opacity: 0.5,
    zIndex: theme.zIndex.drawer + 2,
    position: 'absolute',
  },
  loadingIcon: {
    zIndex: theme.zIndex.drawer + 3,
    position: 'absolute',
    right: '45%',
    bottom: '45%',
  },
  selectedItem: {
    backgroundColor: 'lightgrey !important',
  },
}))
